import { MenuIcon } from "lucide-react";
import { Button } from "./ui/button";
import { Sheet, SheetContent, SheetTrigger } from "./ui/sheet";
import { DISCORD_LINK } from "@/constants/DISCORD_LINK";

function MobileNav() {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline">
          Menu
          <MenuIcon size={20} className="ml-2" />
        </Button>
      </SheetTrigger>
      <SheetContent>
        <nav className="mt-6">
          <ul className="flex flex-col gap-y-4">
            <li>
              <a href="/" className="text-2xl wavy-link">
                Home
              </a>
            </li>
            <li>
              <a href="/about-me" className="text-2xl wavy-link">
                About Me
              </a>
            </li>
            <li>
              <a href="/contact" className="text-2xl wavy-link">
                Contact Me
              </a>
            </li>
            <li>
              <a href="/support-me" className="text-2xl wavy-link">
                Support Me
              </a>
            </li>
            <li>
              <a href={DISCORD_LINK} className="text-2xl wavy-link">
                Join Discord
              </a>
            </li>
          </ul>
        </nav>
      </SheetContent>
    </Sheet>
  );
}

export default MobileNav;
